<template>
  <OverviewWrapper>
    <AppCard :title="$t('All Products')" title-actions>
      <template #titleActions>
        <div class="d-flex flex-column flex-md-row align-end align-md-center justify-end">
          <v-text-field
            v-model="search"
            class="mb-4 mb-md-0 mr-md-4"
            outlined
            hide-details="auto"
            dense
            type="text"
            label="Search Product"
            style="max-width: 200px"
            prepend-inner-icon="mdi-magnify"
          />
          <v-btn to="Products/create" color="secondary" rounded dark>
            <v-icon>mdi-plus</v-icon>
            <span class="d-none d-sm-inline">Produkt erstellen</span>
          </v-btn>
        </div>
      </template>

      <AppPagination v-if="pagination" :pagination="pagination" location="top" />

      <v-data-table
        class="v-data-table__overview"
        :headers="headers"
        :items="products"
        :page="pagination.page"
        :items-per-page="pagination.perPage"
        :loading="pending"
        hide-default-footer
      >
        <template #[`item.category-name`]="{ item }">
          <router-link :to="{ name: 'categories-item', params: { id: item.categorie_id } }">
            {{ item.category.name }}
          </router-link>
        </template>

        <template #[`item.actions`]="{ item }">
          <OverviewActions type="products" :item-id="item.id" info edit remove @onRemove="remove" />
        </template>
      </v-data-table>

      <AppPagination v-if="pagination" :pagination="pagination" />
    </AppCard>
  </OverviewWrapper>
</template>

<script>
import { defineComponent, ref, computed, watch } from '@vue/composition-api'

import OverviewWrapper from '@/layouts/OverviewWrapper'
import AppCard from '@/components/UI/AppCard'
import AppPagination from '@/components/UI/AppPagination'
import OverviewActions from '@/components/UI/OverviewActions'

export default defineComponent({
  name: 'ViewProducts',
  components: {
    OverviewWrapper,
    AppCard,
    AppPagination,
    OverviewActions,
  },
  setup(props, { root: { $store, $route, $router }, root }) {
    const page = ref(1)
    const search = ref(null)
    const headers = [
      {
        sortable: false,
        text: 'Artikel Nr.',
        value: 'number',
      },
      {
        sortable: false,
        text: 'Name',
        value: 'name',
      },
      {
        sortable: false,
        text: 'Category',
        value: 'category-name',
      },
      {
        sortable: false,
        text: 'Actions',
        value: 'actions',
        align: 'right',
        width: '165px',
      },
    ]

    const activeQuery = computed(_ => root.$route.query)

    // store
    const pending = computed(_ => $store.state.products.pending)
    const products = computed(_ => $store.state.products.products)
    const pagination = computed(_ => $store.state.products.pagination)
    const getProducts = payload => $store.dispatch('products/getProducts', payload)
    const removeProduct = productId => $store.dispatch('products/removeProduct', productId)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    page.value = $route.query.page || page.value
    getProducts({ page: page.value })

    watch(activeQuery, value => {
      page.value = value.page ? value.page : 1
      clearTimeout(delayTimer)
      getProductsWithParams()
    })

    const delayTimer = ref(null)
    watch(search, _ => {
      clearTimeout(delayTimer.value)
      delayTimer.value = setTimeout(() => {
        page.value = 1
        getProductsWithParams()
      }, 500)
    })

    const getProductsWithParams = _ => {
      const params = {
        page: page.value,
      }

      if (search.value) {
        params.search = search.value
      }

      getProducts(params)
    }

    const remove = id => {
      if (confirm('Sicher, dass das Produkt gelöscht werden soll?')) {
        if (pending.value) {
          return
        }
        removeProduct(id)
          .then(_ => {
            addToast({
              msg: 'Produkt erfolgreich gelöscht',
              type: 'success',
            })

            if (pagination.value.current === 1 && page.value !== 1) {
              $router.push({ query: { page: page.value - 1 } })
            } else {
              getProductsWithParams()
            }
          })
          .catch(error => {
            addToast({
              msg: 'Löschung fehlgeschlagen',
              type: 'error',
            })
          })
      }
    }

    return {
      pending,
      search,
      products,
      headers,
      pagination,
      remove,
    }
  },
}) //
</script>

<style scoped>
.table-responsive {
  overflow: visible;
}
</style>
